.cont-datoscasos {
  background-color: #028d7b;
  height: 130px;
  -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
  -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
  box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
  margin-bottom: 15px;
}
.cont-titulocasos, .cont-titulomedi{
  text-align: center;
}
.titulocasos{
  font-size: 25px;
  color: #028d7b;
}
.titulo-cerrarcasos {
  font-size: 30px;
  color: rgb(255, 0, 0);
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font: bold;
  cursor: pointer;
  float: right;
}
.opcion {
  color: #fff;
  font-size: 14px;
  text-align: left;  
}
.accionescasos{
  margin: 0px 0px 15px 15px;
  flex: 0%;
  text-align: center;
}
.botoncasosOff {
  color: rgba(182,184,214,1);
  background: transparent;
  outline: none;
  border: solid 1px rgb(18, 149, 167);;
  font-size: 16px;
  padding: 5px 5px;
  border-radius: 10px; 
  margin-right: 20px; 
}    
.botoncasos {
  color: #028d7b;
  background-color: #fff;
  outline: none;
  border: solid 2px aquamarine;
  font-size: 16px;
  padding: 5px 5px;
  border-radius: 10px; 
  margin-right: 20px; 
}
.botoncasos:hover {
  color:  #fff;
  background-color: rgb(35, 80, 69)
}
.cont-tablamedi{
  color: rgb(7, 5, 5);
  top: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}
.tablamedi1{
  background-color: #18ccb4;
  -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
  -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
  box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
  margin-bottom: 15px;
}
.cont-listmedi {
  color: rgb(7, 5, 5);
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}

.listmedi {
  height: 280px;
  width: 100%;
  width: auto;
  overflow: auto;
}

.tablame {
  color: #fff;
  background-color: #7ac5cd;
  width: 100%;
  border: 1px solid #7ac5cd;
  font-size: 16px;
  border-collapse: collapse;
  /* margin: 0 0 1em 0; */
  caption-side: top;
  border-collapse: collapse;  
}
.titulo-casos{
  color: #fff;
  font: bold;
  font-size: 18px;
  margin-left: 35px;       
}